h1,h2,h3,h4,h5,h6,hgroup,
ul,ol,dd,
p,figure,
pre,table,fieldset,hr {
	margin-bottom: 1.5rem;
}

/*------------------------------------*\
    Base Styles
\*------------------------------------*/
body {
	background: $white;
	font-family: $main-font;
	font-size: 14px;
	font-weight: 500;
	color: $main-color;
}
@media #{$medium-up} {
	body {
		font-size: 16px;
	}
}


/*------------------------------------*\
    @group Headings
\*------------------------------------*/
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
	font-size: 60%;
	opacity: .8;
	line-height: 0;
}
h1,h2,h3,h4,h5,h6 {
	text-transform: uppercase;
}
h1, .h1 {
	color: $brand;
	line-height: 1;
}
h2, .h2 {
	color: $brand;
	line-height: 1.05;
}
h3, .h3 {
	color: $brand-accent;
	line-height: 1.05;
}
h4, .h4 {
	color: $gold;
	line-height: 1.1;
}
h5, .h5 {
	color: $brand;
	line-height: 1.1;
}
h6, .h6 {
	color: $brand-accent;
	line-height: 1.1;
}

.heading-lines {
	text-align: center;
    position: relative;
    overflow: hidden;
    span.heading-text {
        display: inline-block;
        vertical-align: baseline;
        zoom: 1;
        *display: inline;
        *vertical-align: auto;
        position: relative;
        padding: 0 10px;

        &::before, &::after {
            content: '';
            display: block;
            width: 1000px;
            position: absolute;
            top: 50%;
            border-top: 2px solid $off-white;
        }
        &::before {right: 100%;}
        &::after {left: 100%;}
    }
}
.heading-lines.heading-left {
	text-align: center;
}
.heading-lines.heading-right {
	text-align: center;
}

/* Alternate headings (uses base font) */
h1.alt,h2.alt,h3.alt,h4.alt,h5.alt,h6.alt {
	text-transform: none;
}
h1.alt, .h1.alt {}
h2.alt, .h2.alt {}
h3.alt, .h3.alt {}
h4.alt, .h4.alt {}
h5.alt, .h5.alt {}
h6.alt, .h6.alt {}

@media #{$medium} {
	.heading-lines.heading-left {
		text-align: left;
		span {
			padding-left: 0;
		}
	}
	.heading-lines.heading-right {
		text-align: right;
		span {
			padding-right: 0;
		}
	}
}

@media only screen and (max-width:28.125em) {
	.break-word {
		word-break: break-all
	}
}
/* @end */


/*------------------------------------*\
    @group Misc Elements
\*------------------------------------*/
p {
	font-size: 1em;
	line-height: 1.5;
	&.intro {
		font-size: rem-calc(18);
	}
}

a {
	color: #84b142;
	transition: all .3s;
	text-decoration: none;
	&:hover,
	&:focus {
		color: $brand;
		text-decoration: none;
	}
}
img {
	box-shadow: 2px 3px 2px rgba(0,0,0,0.35);
	margin-bottom: 1rem;
}
figure {
	margin: 0 0 20px;
	display: table;
	figcaption {
		max-width: 100%;
		background-color: $off-white;
		font-size: rem-calc(14);
		color: $brand;
		text-align: center;
		padding: 10px;
		display: table-caption;
		caption-side: bottom;
	}
	img {
		box-shadow: none;
		margin-bottom: 0;
	}
}
hr {
	height: 1px;
	border-color: $off-white;
	position: relative;
	&::before {
		content: '';
		background: url('/images/cssimg/bg_hr.png') no-repeat center top $white;
		width: 20px;
		height: 17px;
		display: block;
		position: absolute;
		left: 50%;
		top: -8px;
		transform: translateX(-50%);
	}
}
/* @end */


/*------------------------------------*\
    @group Lists
\*------------------------------------*/
ul {
	font-size: 1em;
	padding: 0;
	margin: 20px 0;
	list-style: none;
	li {
		list-style: none;
		padding: 0 0 0 12px;
		margin: 0 0 8px;
		position: relative;
		&::before {
			content: '\f004';
			font-family: $icon-font;
			font-size: 10px;
			color: $brand-accent;
			position: absolute;
			left: 0;
			top: 2px;
		}
	}
	li ul {
		margin: 10px 0 20px;
		li {
			padding-left: 15px;
			&::before {
				content: '\f003';
				font-size: 8px;
				color: $gold;
				top: 5px;
			}
		}
	}
}
ol {
	font-size: 1em;
	margin: 20px 0 15px 20px;
	li {
		line-height: 1.4;
		margin: 0 0 14px;
	}
	ol {
		list-style: lower-alpha;
	}
	ol ol {
		list-style: lower-roman;
	}
}
ul.bare {
	li {
		padding-left: 0;
		&::before {
			display: none;
		}
	}
}
[class*="block-grid-"] > li {
	background: none;
	&::before {
		display: none;
	}
}
@media #{$medium-up} {
	ul.split-list-2,
	ol.split-list-2 {
		margin-left: 0;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		margin-left: 0;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
}
/* @end */


/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		padding: 0;
		margin: 0;
		&::before {
			display: none;
		}
	}
	li ul li {
		background: none;
	}
	li ul li ul {
		display: none !important;
	}
	li ul li ul li {
		background: none;
	}
}
/* @end */


/*------------------------------------*\
    @group Blockquote
\*------------------------------------*/
blockquote {
	font-size: rem-calc(24);
	text-align: center;
	line-height: 1.4;
	padding: 0 20px 0 35px;
	margin: 20px 0;
	position: relative;
	&::before {
		content: open-quote;
		font-size: 90px;
		color: $brand-accent;
		position: absolute;
		left: 0;
		top: -26px;
	}
	&::after {
		content: no-close-quote;
	}
	p {
		margin: 0;
	}
}
.quotee {
	font-size: rem-calc(20);
	text-align: center;
	color: $brand-accent;
	p {
		font-weight: 700;
	}
	img {
		border: solid 5px $brand-accent;
		border-radius: $border-round;
	}
}

blockquote.alt-quote {
	max-width: 800px;
	color: $brand;
	font-style: italic;
	padding-left: 50px;
	margin-left: auto;
	margin-right: auto;
}
/* @end */


/*------------------------------------*\
    @group Callout Panels
\*------------------------------------*/
.panel {
	background: $brand;
	background: linear-gradient(170deg, lighten($brand, 5%) 50%, $brand 50%);
	text-align: center;
	padding: 30px 30px 35px;
	margin: 0 0 50px;
	border: none;
	border-left: solid 10px $brand-accent;
	h2, h3, h4, li {
		color: $white;
	}
	p {
		font-size: 1rem;
		color: $white;
	}
	img {
		box-shadow: none;
		margin: 0 0 35px;
	}
	.button {
		font-size: rem-calc(18);
		color: $white;
		border-color: $white;
		padding: 7px 15px;
		margin: 0;
		&:hover,
		&:focus {
			background: $white;
			color: $brand;
		}
		.icon {
			font-size: 25px;
			vertical-align: top;
			&.icon-dental-exam {
				vertical-align: -3px;
			}
		}
	}
	a {
		&:hover, &:focus {
			color: $dark-gray;
		}
	}
}
.panel.light {
	background: #F0ECE2;
	background: linear-gradient(158deg, #F3F0E8 50%, #F0ECE2 50%);
	border-color: $brand;
	h3 {
		font-size: rem-calc(26);
		color: $brand;
		&::before {
			background-color: $brand;
		}
	}
	p {
		font-size: 1rem;
		color: $main-color;
	}
	.button {
		font-size: rem-calc(15);
		color: $brand;
		padding: 7px 13px;
		border-width: 2px;
		border-color: $brand;
		&:hover,
		&:focus {
			background: $brand;
			color: $white;
		}
	}
}

@media #{$medium-up} {
	.panel {
		text-align: left;
		img {
			margin: 0;
		}
		h2 {
			@include heading-border($brand-accent);
		}
	}
}
/* @end */


/*------------------------------------*\
    @group Buttons
\*------------------------------------*/
.button,
form input[type="submit"] {
	background: none;
	font-size: rem-calc(20);
	color: $brand;
	padding: 15px 20px;
	border: solid 2px $brand;
	transition: all .2s;
	display: inline-block;
	line-height: 1.2;
	&:hover,
	&:focus {
		background: $brand;
		color: $white;
		text-decoration: none;
	}
	&.pre-icon {
		.icon {
			margin-right: 7px;
			vertical-align: middle;
		}
	}
	&.post-icon {
		.icon {
			margin-left: 7px;
			vertical-align: middle;
		}
	}
}
.button.small {
	font-size: rem-calc(15);
	padding: 7px 13px;
	border-width: 2px;
}
.button.large {
	font-size: rem-calc(30);
	text-transform: uppercase;
	padding: 17px 28px;
}
.button.short {
	padding-top: 8px;
	padding-bottom: 8px;
}
.button.brand {
	color: $brand;
	border-color: $brand;
	&:hover,
	&:focus {
		background: $brand;
		color: $white;
	}
	&.solid {
		background: $brand;
	}
}
.button.gold {
	color: $gold;
	border-color: $gold;
	&:hover,
	&:focus {
		background: $gold;
		color: $white;
	}
	&.solid {
		background: $gold;
	}
}
.button.dark-gray {
	color: $dark-gray;
	border-color: $dark-gray;
	&:hover,
	&:focus {
		background: $dark-gray;
		color: $white;
	}
	&.solid {
		background: $dark-gray;
	}
}
.button.white {
	color: $white;
	border-color: $white;
	&:hover,
	&:focus {
		background: $white;
		color: $main-color;
	}
	&.solid {
		background: $white;
		color: $main-color;
	}
}
.button.solid {
	color: $white;
	&:hover,
	&:focus {
		background: $brand-accent;
		color: $white;
		border-color: $brand-accent;
	}
}
/* @end */


/*------------------------------------*\
    @group Font Helpers
\*------------------------------------*/
.text-xl {font-size:1.5em}
.text-lg {font-size:1.25em}
.text-sm {font-size:.875em}
.text-xs {font-size:.75em}

.text-shadow {text-shadow: $text-shadow;}

.text-brand {color: $brand;}
.text-brand-accent {color: $brand-accent;}
.text-off-white {color: $off-white;}
.text-white {color: $white;}
.text-dark-gray {color: $dark-gray;}
.text-gray {color: $gray;}
.text-light-gray {color: $light-gray;}
.text-gold {color: $gold;}
.text-black {color: $black;}
/* @end */


/*------------------------------------*\
    @group Text Highlights
\*------------------------------------*/
.highlight {
	color: $brand;
}
.highlight-alt {
	color: $brand;
	font-weight: 700;
	text-shadow: $text-shadow;
}
/* @end */


/*------------------------------------*\
    @group Background Helpers
\*------------------------------------*/
.bg-brand {background-color: $brand;}
.bg-brand-accent {background-color: $brand-accent;}
.bg-off-white {background-color: $off-white;}
.bg-white {background-color: $white;}
.bg-dark-gray {background-color: $dark-gray;}
.bg-gray {background-color: $gray;}
.bg-light-gray {background-color: $light-gray;}
.bg-gold {background-color: $gold;}
.bg-black {background-color: $black;}
.bg-facebook {background-color: $facebook;}
.bg-youtube {background-color: $youtube;}

.box-shadow {box-shadow: $box-shadow;}

.bg-round {border-radius: $border-round;}
/* @end */


/*------------------------------------*\
    @group Image Helpers
\*------------------------------------*/
.content-section-full {
	padding: 30px 0 10px;
	&.bg-gradient {
		background: linear-gradient(170deg, lighten($brand, 3%) 50%, $brand 50%);
		font-size: rem-calc(16);
		color: $white;
		text-align: center;
		h2 {
			color: $white;
		}
	}
}
.content-intro-section {
	font-size: rem-calc(18);
	padding: 0 0 20px 0;
}
.content-section {
	padding: 0 0 25px 0;
}
.content-sub-section {
	padding-bottom: 20px;
}
.media-section {
	color: $white;
	padding: 30px 0 10px;
}

@media #{$medium-up} {
	.content-section-full {
		padding: 50px 0 30px;
		&.bg-gradient {
			font-size: rem-calc(18);
			text-align: left;
		}
	}
	.content-intro-section {
		font-size: rem-calc(20);
		padding: 0 0 40px;
	}
	.content-section {
		padding: 0 0 50px 0;
	}
	.content-sub-section {
		padding-bottom: 25px;
	}
	.media-section {
		padding: 50px 0 35px;
	}
	.content-padded-section {
		max-width: 600px;
		text-align: center;
		margin: 0 auto;
	}
}
/* @end */


/*------------------------------------*\
    @group Image Helpers
\*------------------------------------*/
.fig-right,
.fig-left {
	margin-left: auto;
	margin-right: auto;
}
.fig-left,
.fig-right {
	margin-left: auto;
	margin-right: auto;
}
.fig-left-all {
	float: left;
	margin-right: 20px;
}
.fig-right-all {
	float: right;
	margin-left: 20px;
}
.fig-full.fig-center {
	margin: 0 auto 15px;
	display: block;
}

.img-left,
.img-right {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.img-left-all {
	float: left;
	margin-right: 20px;
}
.img-right-all {
	float: right;
	margin-left: 20px;
}
.img-full.img-center {
	margin: 0 auto 15px;
	display: block;
}

.img-bare {
	box-shadow: none;
}
@media #{$medium-up} {
	.img-left,
	.fig-left {
		float: left;
		margin-right: 30px;
	}
	.img-right,
	.fig-right {
		float: right;
		margin-left: 30px;
	}
}
/* @end */


/*------------------------------------*\
    @group Spacing Helpers
\*------------------------------------*/
.mb-0 {margin-bottom: 0 !important;}
.mb-5 {margin-bottom: 5px !important;}
.mb-10 {margin-bottom: 10px !important;}
.mb-15 {margin-bottom: 15px !important;}
.mb-20 {margin-bottom: 20px !important;}
.mb-25 {margin-bottom: 25px !important;}
.mb-30 {margin-bottom: 30px !important;}
.mb-35 {margin-bottom: 35px !important;}
.mb-40 {margin-bottom: 40px !important;}

.mt-0 {margin-top: 0 !important;}
.mt-5 {margin-top: 5px !important;}
.mt-10 {margin-top: 10px !important;}
.mt-15 {margin-top: 15px !important;}
.mt-20 {margin-top: 20px !important;}
.mt-25 {margin-top: 25px !important;}
.mt-30 {margin-top: 30px !important;}
.mt-35 {margin-top: 35px !important;}
.mt-40 {margin-top: 40px !important;}

.stacked {
	margin-top: 0;
	margin-bottom: 0;
}
/* @end */


/*------------------------------------*\
    @group Social Media brand Colors
\*------------------------------------*/
.social-twitter {@include link-colors($color:$twitter);}
.social-facebook {@include link-colors($color:$facebook);}
.social-googleplus {@include link-colors($color:$googleplus);}
.social-pinterest {@include link-colors($color:$pinterest);}
.social-linkedin {@include link-colors($color:$linkedin);}
.social-youtube {@include link-colors($color:$youtube);}
.social-vimeo {@include link-colors($color:$vimeo);}
.social-instagram {@include link-colors($color:$instagram);}
.social-quora {@include link-colors($color:$quora);}
.social-foursquare {@include link-colors($color:$foursquare);}
.social-stumbleupon {@include link-colors($color:$stumbleupon);}
.social-soundcloud {@include link-colors($color:$soundcloud);}
/* @end */