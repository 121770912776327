@mixin link-colors ($color: null, $bg: null) {
	background-color: $bg;
	color: $color;
	&:hover, &:focus {
		@if ($bg) {
			background-color: darken($bg,10%);
		}
		@if ($color and $bg == null) {
			color: darken($color,10%);
		}
	}
}

@mixin heading-border ($color) {
	padding: 0 0 20px;
	margin: 0 0 20px;
	position: relative;
	&::before {
	    content: '';
	    background: $color;
	    width: 60px;
	    height: 5px;
	    position: absolute;
	    left: 0;
	    bottom: 0;
	}
}