.blog-template .page-content {
	padding-top: 45px;
	padding-bottom: 0;
}
/* Overall */
.blog {
	h2 {
		font-size: rem-calc(24);
		color: $brand;
		text-transform: none;
		margin: 0 0 10px;
		a {
			color: $brand;
			&:hover, &:focus {
				color: $brand-accent;
			}
		}
		@include heading-border($brand);
	}
	.byline {
		font-family: $alt-font;
		font-size: rem-calc(14);
		color: $brand;
	}
}
.blog-title {
	background: $brand;
	color: $white;
	border-bottom: solid 5px $brand-accent;
	margin: 0 0 45px;
	padding: 15px 20px 5px;
	h1 {
		font-size: rem-calc(32);
		color: #DEE3AD;
		margin: 0 0 8px;
		a {
			color: #DEE3AD;
			&:hover, &:focus {
				color: $off-white;
			}
		}
	}
	p {
		margin: 0 0 10px;
	}
}
.blog-features {
	background: #4C8574;
	background: linear-gradient(170deg, lighten($brand, 3%) 50%, $brand 50%);
	color: $white;
	padding: 0;
	margin: 30px 0 0;
	aside {
		border: none;
		.feature-box {
			padding: 25px 15px 15px;
		}
	}
	h4 {
		font-size: rem-calc(22);
		color: $white;
		@include heading-border($white);
	}
	a {
		color: #DEE3AD;
		&:hover,
		&:focus {
			color: $off-white;
		}
	}
	li {
		padding: 0;
		&::before {
			display: none;
		}
	}
}
.blog .page-num {
	font-size: rem-calc(24);
	vertical-align: top;
	.page-num-inner {
		padding: 0 8px;
	}
}
.blog .page-nav .nav-arrow.disabled {
	color: $off-white;
	cursor: default;
}
@media #{$medium-up} {
	.blog-features {
		padding: 0 15px;
		aside {
			border: solid 2px $gray;
			.feature-box {
				&:first-child {
					border-top: none;
				}
			}
			&.row {
				border: none;
				margin-top: 30px;
				.feature-box {
					border: none;
				}
			}
		}
	}
}

/* Post list preview */
.blog .post-preview-wrap {
	.post-preview {
		margin-bottom: 30px;
	}
}

@media #{$medium-up} {
	.blog .post-preview-wrap {
		&:nth-child(2n+1) {
			clear: left;
		}
		.post-preview {
			padding-right: 15px;
		}
		&:nth-child(2n) .post-preview {
			padding-right: 0;
			padding-left: 15px;
		}
	}
}

@media only screen and (min-width:58.75em) {
	.blog-template .page-content {
		padding-top: 75px;
		padding-bottom: 0;
	}
}

@media #{$large-up} {
	.blog .post-preview-wrap {
		.post-preview {
			padding-right: 30px;
		}
		&:nth-child(2n) .post-preview {
			padding-right: 0;
			padding-left: 30px;
		}
	}
}
/* Full entry */
.blog .post {
	.post-share {
		border-top: solid 1px $brand;
		border-bottom: solid 1px $brand;
		padding: 20px 0 13px;
		margin: 30px 0;
		position: relative;
		.share-title {
			font-size: 20px;
			color: $brand;
			margin-right: 20px;
			vertical-align: top;
		}
		.addthis_sharing_toolbox {
			display: inline-block;
		}
	}
	.post-tags {
		.tags-title {
			margin-right: 20px;
		}
		ul {
			margin: 0;
			padding: 0;
			display: inline-block;
			li {
				font-size: 14px;
				padding: 0;
				margin: 0 10px 10px 0;
				display: inline-block;
				&::before {
					display: none;
				}
			}
		}
	}
	.page-nav {
		display: none;
	}
}