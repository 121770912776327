/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
	background-color: $gold;
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	padding: 40px 0;
}

@media #{$medium-up} {
	.hero {padding: 112px 0 106px;}
}

/* @end */


/*------------------------------------*\
    @group Inner Pages Hero
\*------------------------------------*/
.inner-hero {
	.hero {
		background: url('/images/hero/bg_office_wall.jpg') no-repeat right top;
		height: 230px;
		position: relative;
		padding: 0;
		border-bottom: 5px solid $off-white;
		.hero-text {
			left: 0;
			right: 0;
			bottom: -25px;
			position: absolute;
			text-align: center;
			padding: 0 15px;
		}
		h1 {
			background: $white;
			font-size: rem-calc(24);
			box-shadow: $box-shadow;
			padding: 15px 20px;
			margin: 0;
			display: inline-block;
		}
		&.bg-our-practice,
		&.bg-patient-cases {
			background-image: url('/images/hero/bg_downtown_operatorium.jpg');
		}
		&.bg-services,
		&.bg-dental-implants,
		&.bg-digital-dentistry,
		&.bg-sedation-dentistry,
		&.bg-smart-amalgam-removal {
			background-image: url('/images/hero/bg_dr_rosa.jpg');
			background-position: center top;
			background-color: #958458;
		}
		&.bg-comprehensive-dentistry,
		&.bg-cosmetic-dentistry,
		&.bg-wisdom-tooth-extraction {
			background-image: url('/images/hero/bg_dental_cleaning.jpg');
		}
		&.bg-headaches-and-tmd,
		&.bg-orthotropics,
		&.bg-functional-orthodontics {
			background-image: url('/images/hero/bg_dr_ririe.jpg');
		}
		&.bg-integrative-dentistry {
			background-image: url('/images/hero/bg_office_empty_chair.jpg');
			background-position: left top;
		}
		&.bg-invisalign-and-orthodontics,
		&.bg-sleep-apnea-and-snoring {
			background-image: url('/images/hero/bg_dr_dan.jpg');
		}
		&.bg-kor-whitening {background-image: url('/images/hero/bg_kor_whitening.jpg');}
		&.bg-patients {
			background-image: url('/images/hero/bg_receptionist_paperwork.jpg');
			background-position: left top;
		}
		&.bg-headaches-and-tmd,
		&.bg-orthotropics {
			background-image: url('/images/hero/bg_dr_ririe.jpg');
		}
	}
}

@media #{$medium-up} {
	.inner-hero {
		.hero {
			background-image: url('/images/hero/bg_office_wall_med.jpg');
			background-size: cover;
			background-position: center center;
			height: 300px;
			h1 {
				font-size: rem-calc(48);
				padding: 23px 34px;
			}
			&.bg-our-practice,
			&.bg-patient-cases {
				background-image: url('/images/hero/bg_downtown_operatorium_med.jpg');
			}
			&.bg-services,
			&.bg-dental-implants,
			&.bg-digital-dentistry,
			&.bg-sedation-dentistry,
			&.bg-smart-amalgam-removal {
				background-image: url('/images/hero/bg_dr_rosa_med.jpg');
			}
			&.bg-comprehensive-dentistry,
			&.bg-cosmetic-dentistry,
			&.bg-wisdom-tooth-extraction {
				background-image: url('/images/hero/bg_dental_cleaning_med.jpg');
			}
			&.bg-headaches-and-tmd,
			&.bg-orthotropics {
				background-image: url('/images/hero/bg_dr_ririe_med.jpg');
			}
			&.bg-integrative-dentistry {
				background-image: url('/images/hero/bg_office_empty_chair_med.jpg');
				background-position: center center;
			}
			&.bg-invisalign-and-orthodontics,
			&.bg-sleep-apnea-and-snoring {
				background-image: url('/images/hero/bg_dr_dan_med.jpg');
			}
			&.bg-kor-whitening {background-image: url('/images/hero/bg_kor_whitening_med.jpg');}
			&.bg-patients {
				background-image: url('/images/hero/bg_receptionist_paperwork_med.jpg');
				background-position: center center;
			}
		}
	}
}


@media  only screen and (-webkit-min-device-pixel-ratio: 1.3) and (max-width:40em),
only screen and (-o-min-device-pixel-ratio: 13/10) and (max-width:40em),
only screen and (min-resolution: 120dpi) and (max-width:40em) {
	.inner-hero {
		.hero {
			background: url('/images/hero/bg_office_wall.jpg') no-repeat right top;
			background-size: 640px 230px;
			&.bg-our-practice,
			&.bg-patient-cases {
				background-image: url('/images/hero/bg_downtown_operatorium@2x.jpg');
			}
			&.bg-services,
			&.bg-dental-implants,
			&.bg-digital-dentistry,
			&.bg-sedation-dentistry,
			&.bg-smart-amalgam-removal {
				background-image: url('/images/hero/bg_dr_rosa@2x.jpg');
			}
			&.bg-comprehensive-dentistry,
			&.bg-cosmetic-dentistry,
			&.bg-wisdom-tooth-extraction {
				background-image: url('/images/hero/bg_dental_cleaning@2x.jpg');
			}
			&.bg-headaches-and-tmd,
			&.bg-orthotropics {
					background-image: url('/images/hero/bg_dr_ririe@2x.jpg');
				}
			&.bg-integrative-dentistry {
				background-image: url('/images/hero/bg_office_empty_chair@2x.jpg');
			}
			&.bg-invisalign-and-orthodontics,
			&.bg-sleep-apnea-and-snoring {
				background-image: url('/images/hero/bg_dr_dan@2x.jpg');
			}
			&.bg-kor-whitening {background-image: url('/images/hero/bg_kor_whitening@2x.jpg');}
			&.bg-patients {
				background-image: url('/images/hero/bg_receptionist_paperwork@2x.jpg');
			}
		}
	}
}

/* @end */



/*------------------------------------*\
    @group Hero Testimonial
\*------------------------------------*/
.hero-testimonial {
	background: url('/images/hero/bg_wall_texture.jpg');
	background-size: cover;
	text-align: center;
	padding: 50px 0 50px;
	margin: 0 0 50px;
	h1 {
		margin: 0 0 30px;
	}
	img {
		border-radius: $border-round;
		margin: 0;
	}
	.description {
		font-size: rem-calc(20);
		border-left: solid 10px $brand-accent;
		padding-left: 20px;
		margin: 0 0 35px;
	}
}

@media #{$medium-up} {
	.hero-testimonial {
		height: 515px;
		background: url('/images/hero/bg_wall_texture_med.jpg');
		text-align: left;
		> .row {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
		.media {
			text-align: center;
		}
	}
}

@media #{$large-up} {
	.hero-testimonial {
		.testimonial-text {
			padding-top: 20px;
		}
		.description {
			padding-right: 30px;
		}
	}
}
/* @end */