.mobile-menu-toggle {
	font-family: $alt-font;
	font-size: rem-calc(16);
	font-weight: 700;
	text-transform: uppercase;
	text-align: right;
	position: relative;
	.icon {
		font-size: 14px;
		vertical-align: 1px;
		margin-right: 5px;
		&.icon-times {
			font-size: 16px;
			margin-right: 5px;
			vertical-align: 1px;
		}
	}
	a {
		margin: 24px 0 0;
		display: inline-block;
		&:hover,
		&:focus {
			color: #DEE3AD;
			text-decoration: none;
		}
	}
}
.mobile-menu-anchor {
	padding: 0;
}
.main-nav {
	display: none;
}
@media only screen and (min-width:48.75em) {
	.mobile-menu-toggle,
	.mobile-menu-anchor {
		display: none;
	}
	.main-nav {
		// Color is default state and alt is for alternate states (selected, hover, focus, etc.). Bg is for li and anchor hovers as needed
		$nav-color: $white;
		$nav-color-alt: $white;
		$nav-bg: none;
		$nav-bg-alt: none;
		$nav-child-color: $white;
		$nav-child-color-alt: $brand;
		$nav-child-bg: none;
		$nav-child-bg-alt: none;

		font-family: $alt-font;
		font-weight: 700;
		text-transform: uppercase;
		display: block;
		a {
		    font-size: rem-calc(15);
		    color: $nav-color;
		    line-height: 1.2;
		    padding: 21px 10px 17px;
		    display: block;
		}
	    a:hover,
	    a:focus,
	    li.active > a {
	        color: $nav-color-alt;
	    }
		ul {
		    text-align: right;
		}
		li {
		    margin: 0 0 0 5px;
		    display: inline-block;
		    position: relative;
		    padding-bottom: 2px;
		    &:first-child {
		        margin-left: 0;
		    }
		    &.selected {
				border-bottom: solid 2px $white;
		    	padding-bottom: 0;
		    	&:hover {
		    		border-bottom: none;
		    		padding-bottom: 2px;
		    	}
		    }
		    &.selected > a,
		    &.selected > a::after,
		    &.has-dropdown.selected > a::after {
		    	color: $nav-color-alt;
		    }
		    &:hover {
		    	background: darken($brand, 5%);
		    }
		}
		li.has-dropdown:hover .dropdown {
			visibility: visible;
			opacity: 1;
		}
		li.has-dropdown > a::after {
			content: '\f008';
			font-family: $icon-font;
			font-size: 8px;
			vertical-align: 3px;
			margin-left: 5px;
			transition: all .3s;
		}
		li.has-dropdown > a:hover::after {
			color: $nav-color-alt;
		}
		.dropdown {
			min-width: 16.875em;
			background: $brand;
			text-align: left;
			text-shadow: $text-shadow;
			padding: 2px 0 10px;
			margin: 0;
			border-bottom: solid 5px $brand-accent;
		    visibility: hidden;
		    opacity: 0;
		    transition: all .3s;
		    position: absolute;
		    top: 58px;
		    left: 0;
			z-index: 100;
			li {
				font-size: rem-calc(15);
				text-transform: none;
				padding: 0;
				margin: 0;
				display: block;
				&.selected {
					border-bottom: none;
					&:hover {
						padding-bottom: 0;
					}
				}
				&.selected > a {
					background: rgba($brand-accent,.2);
					border-left-color: $brand-accent;
				}
			}
			a {
				color: $nav-child-color;
				padding: 12px 15px 12px 10px;
				border-left: solid 5px $brand;
				line-height: 1.1;
				transition: background-color .3s;
				&:hover,
				&:focus {
					// background: rgba($brand-accent,.2);
					border-left-color: $brand-accent;
				}
			}
		}
	}
}

@media only screen and (min-width:48.75em) {
	nav .overview-link {
		display: none !important;
	}
}
