//Fonts
$sans-serif-font: "semplicitapro", "Helvetica Neue", Arial, sans-serif;
$serif-font: "serifa", Rockwell, "Lucida Sans Unicode", serif;
$icon-font: 'BoiseDentist';
$heading-font: $sans-serif-font;
$main-font: $serif-font;
$alt-font: $heading-font;

//Color Pallete
$brand:				 #376e6e;
$brand-accent:		 #b5c69b;
$off-white: 		 #f0f2ed;
$white: 			 #FFFFFF;
$dark-gray: 		 #383838;
$gray: 				 #696969;
$light-gray: 		 #AAAAAA;
$gold: 				 #849171;
$black:              #000000;

$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);


//Social media brand colors
$twitter: 			 #00aced;
$facebook: 			 #3b5998;
$googleplus: 		 #dd4b39;
$pinterest: 		 #cb2027;
$linkedin: 			 #007bb6;
$youtube: 			 #bb0000;
$vimeo: 			 #aad450;
$instagram: 		 #517fa4;
$quora: 			 #a82400;
$foursquare: 		 #0072b1;
$stumbleupon: 		 #EB4823;
$soundcloud: 		 #ff3a00;

//Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $gray;

//Shadows
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow:  0 2px 4px rgba(0,0,0,0.3);

//Border rounding
$border-round: 1000px;
$border-radius: 4px;