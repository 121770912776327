.home-template {
	.page-header {
		background: none;
		width: 100%;
		position: absolute;
		.contact-header {
			margin-bottom: 10px;
		}
	}
	.hero {
		background-image: url('/images/hero/boise_skyline.jpg');
		background-color: #62716a;
		background-position:  center top;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		padding: 150px 0 50px;
		.hero-content {
			max-width: 800px;
			padding: 0 10px;
			margin: 0 auto;
		}
		h1 {
			font-size: rem-calc(30);
			color: $white;
			line-height: 1.2;
			padding: 0 0 30px;
			@include heading-border($white);
			&::before {
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.subhead {
			font-size: rem-calc(18);
		}
		.button-group {
			max-width: 350px;
			padding: 0 20px;
			margin: 0 auto;
			a {
				display: block;
			}
			.play-button {
				margin-bottom: 15px;
				display: inline-block;
			}
			.button {
				width: 100%;
			}
		}
		.media {
			text-align: center;
		}
		.play-button {
			font-size: 60px;
			color: $white;
			line-height: 1;
			&:hover, &:focus {
				color: $brand-accent;
			}
		}
	}
	.page-content {
		padding: 0;
	}
	.home-content-section {
		padding: 50px 0 30px;
	}
	.section-icon {
		font-size: 125px;
		color: $brand;
		line-height: 1;
		margin: 0 0 30px;
	}
}
.media-cover-section {
	background: $brand;
	background-image: linear-gradient(170deg, lighten($brand, 5%) 50%, $brand 50%);
	background-repeat: no-repeat;
	background-position: right;
	background-size: 100%;
	color: $white;
	overflow: hidden;
	h2 {
		color: $white;
		@include heading-border($white);
	}
	.image-container {
		height: 250px;
		background-image: url('/images/home/dr_rosa_assistant_patient.jpg');
		background-size: cover;
		background-position: center center;
	}
	.section-text-container {
		padding: 50px 15px 30px;
	}
	.button {
		margin: 0;
	}
}

@media #{$medium-up} {
	.home-template .hero {
		background-image: url('/images/hero/boise_skyline_med.jpg');
		padding: 200px 0 110px;
		h1 {
			font-size: rem-calc(46);
		}
		.subhead {
			font-size: rem-calc(20);
		}
		.button-group {
			max-width: 100%;
			a {
				display: inline-block;
				margin-right: 25px;
				&:last-child {
					margin-right: 0;
				}
			}
			.button {
				width: auto;
			}
			.play-button {
				.icon {
					vertical-align: -12px;
				}
			}
		}
	}
	.home-template {
		.page-header {
			.top-nav {
				background: none;
			}
			.nav {
				background: rgba($brand,.4);
			}
		}
	}
	.media-cover-section {
		.image-container {
			background-image: url('/images/home/dr_rosa_assistant_patient_640.jpg');
		}
	}
}

@media only screen and (min-width:54.375em) {
	.media-cover-section {
		height: 570px;
		.image-container {
			width: 45%;
			height: 570px;
			background-image: url('/images/home/dr_rosa_assistant_patient_880.jpg');
			background-size: cover;
			padding-bottom: 66.7361835%;
			float: left;
		}
		.section-text-container {
			width: 55%;
			height: 570px;
			padding: 0 15px 0 50px;
			float: left;
			.section-text {
				max-width: 37.3em;
				padding: 0;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

@media only screen and (min-width:58.75em) {
	.home-template {
		.page-header {
			.top-nav {
				height: auto;
				background: none;
				padding-top: 20px;
				padding-bottom: 0;
			}
			.logo {
				margin-top: -22px;
				img {
					background: none;
					box-shadow: none;
					padding: 0;
				}
			}
			.nav {
				background: none;
			}
			.main-nav {
				margin-top: 10px;
			}
			.main-nav .dropdown {
				background-color: rgba($brand,.9);
				top: 36px;
			}
			.main-nav > ul > li {
				&:hover {
					background: none;
				}
				> a {
					padding: 10px;
					&:hover,
					&:focus {
						color: #DEE3AD;
					}
				}
			}
		}
	}
}

@media #{$large-up} {
	.home-template {
		.hero {
			background-image: url('/images/hero/boise_skyline_lrg.jpg');
			height: 900px;
			padding: 300px 0 120px;
		}
		.home-content-section {
			padding: 100px 0 80px;
		}
	}
	.media-cover-section {
		.image-container {
			background-image: url('/images/home/dr_rosa_assistant_patient_lrg.jpg');
			padding: 0;
		}
	}
}
