/*------------------------------------*\
    @group Forms
\*------------------------------------*/
form .req {
	color: $brand-accent;
}
form .button {
	background: none;
	padding: 15px 20px;
	&:hover,
	&:focus {
		background: $brand-accent;
	}
	.icon {
		vertical-align: 2px;
	}
	&.pre-icon .icon {
		margin-right: 5px;
	}
}
form input[type="text"],
form textarea,
form select {
	background-color: $off-white;
	font-family: $heading-font;
	font-size: 1rem;
	color: $dark-gray;
	border: none;
	border-radius: 0;
	box-shadow: none;
	padding: 9px 14px;
	margin: 0 0 25px;
}
form label,
form fieldset legend {
	font-size: 1rem;
	font-weight: normal;
	color: $brand;
}
form fieldset {
	border: 0;
	padding: 0;
	margin: 0;
	label {
		color: $dark-gray;
	}
}
form select {
	font-size: 1rem;
	padding: 4px .5rem;
}
form input[type="radio"] {
	margin-right: 5px;
}
form input[type="radio"] + label,
form input[type="check"] + label {
	font-size: 1rem;
	color: $dark-gray;
}
form .list-wrap {
	margin-bottom: 10px;
}

form .radio-list {
	display: flex;
	label {
		margin-right: 16px;
	}
}
/* @end */


/*------------------------------------*\
    @group Icon Grid
\*------------------------------------*/
.icon-heading-grid {
	h3 {
		font-size: rem-calc(20);
		color: $white;
		margin: 0;
		padding: 0 15px 0 0;
		transition: all .3s;
	}
	a {
		width: 100%;
		background-color: $brand;
		background-image: linear-gradient(170deg, lighten($brand,3%) 50%, $brand 50%);
		border-left: solid 5px $brand-accent;
		display: table;
		transition: all .3s;
		> * {
			display: table-cell;
			vertical-align: middle;
		}
		&:hover, &:focus {
			background-color: darken($brand, 5%);
			background-image: none;
			border-color: $brand-accent;
		}
	}
	img {
		width: 85px;
		box-shadow: none;
		margin: 0;
	}
	.icon-wrap {
		width: 85px;
	}
}
/* @end */


/*------------------------------------*\
    @group Bio with Heading Highlight
\*------------------------------------*/
.bio-highlight {
	background: $brand;
	background: linear-gradient(170deg, lighten($brand, 3%) 50%, $brand 50%);
	text-align: center;
	padding: 35px 0 10px;
	margin-bottom: 30px;
	img {
		margin: 0 0 30px;
	}
	p {
		font-size: rem-calc(24);
		color: $white;
	}
	.quote {
		text-align: left;
		padding-left: 35px;
		position: relative;
		&::before {
			content: '\201c';
			font-size: 80px;
			line-height: 1;
			position: absolute;
			left: 0;
			top: -10px;
		}
	}
}
@media #{$medium-up} {
	.bio-highlight {
		padding: 0;
		.image {
			margin-top: 30px;
			img {
				margin: 0;
			}
		}
		.details {
			padding: 55px 10px 20px;
			margin: 10px 0;
		}
	}
}
@media only screen and (min-width:46.25em) {
	.bio-highlight {
		margin-top: 30px;
		margin-bottom: 60px;
		.image {
			margin-top: -20px;
			margin-bottom: -20px;
		}
	}
}
@media #{$large-up} {
	.bio-highlight {
		p {
			font-size: rem-calc(32);
			line-height: 1.2;
		}
	}
}
/* @end */


/*------------------------------------*\
    @group General Bio
\*------------------------------------*/
.bio {
	text-align: center;
	margin-bottom: 30px;
	.name {
		max-width: 200px;
		width: 100%;
		text-align: center;
		box-shadow: 2px 3px 2px rgba(0,0,0,0.35);
		margin: 0 auto 30px;
		h3 {
			background: $gold;
			color: $white;
			padding: 15px;
			margin: 0;
		}
		img {
			box-shadow: none;
			margin: 0;
		}
	}
}

@media #{$medium-up} {
	.bio {
		text-align: left;
		.name {
			margin: 0 30px 20px 0;
			float: left;
		}
		.bio-text {
			overflow: hidden;
		}
	}
}
@media #{$large-up} {
	.bio {
		&:nth-child(2n+3) {
			clear: left;
		}
	}
}

/* @end */


/*----------------------------------------------------*\
    @group Mixed Media Grid with image/heading
\*----------------------------------------------------*/
.mm-grid {
	.grid-item {
		margin: 0 0 30px;
		text-align: center;
		img {
			margin: 0 0 15px;
		}
		.title {
			font-family: $alt-font;
			font-size: 1em;
			color: $gold;
			transition: all .3s;
		}
		a {
			display: block;
			&:hover .title {
				color: $brand;
			}
		}
	}
}

.mm-grid.mm-grid-center {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px,220px));
	grid-column-gap: 30px;
	justify-content: center;
}
/* @end */


/*------------------------------------*\
    @group Location Callout
\*------------------------------------*/
.location-callouts {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	grid-column-gap: 50px;
}


.location-callout-heading {
	font-size: rem-calc(32);
	text-align: center;
}
.location-callout {
	max-width: 470px;
	margin: 0 auto 40px;
	.location-info {
		background-color: $brand;
		background-image: linear-gradient(170deg, lighten($brand, 3%) 50%, $brand 50%);
		color: $white;
		padding: 30px 30px 20px;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		p {
			margin: 0 0 15px;
		}
		.address {
			padding-right: 16px;
		}
	}
	.location-media {
		position: relative;
		img {
			box-shadow: none;
			margin: 0;
		}
		.action {
			width: 100%;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
		.button {
			font-size: rem-calc(18);
			margin: 0;
		}
	}
	.hours-operation {
		width: 100%;
		.heading {
			font-size: rem-calc(16);
			font-weight: bold;
			margin-bottom: 10px;
			line-height: 1;
		}
	}
}

@media only screen and (min-width: 23.75em) {
	.location-callout {
		.location-info {
			.address {
				float: left;
			}
			.directions {
				float: right;
				.button {
					display: block;
					margin-bottom: 10px;
				}
			}
		}
	}
}

@media #{$medium-up} {
	.location-callout-heading {
		text-align: left;
	}
}
/* @end */


/*------------------------------------*\
    @group Review Cards
\*------------------------------------*/
.review-ratings {
	img {
		box-shadow: none;
	}
	.review-card {
		margin: 16px 0 32px;
	}
	.inner {
		max-width: 470px;
	}
	.heading {
		height: 190px;
		background-color: #6c5840;
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
		padding: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		h3 {
			font-size: rem-calc(28);
			color: $white;
			margin: 0;
		}
		&.bg-office-wall {
			background-image: url('/images/reviews/bg_office_wall_sml.jpg');
		}
		&.bg-lamp-closeup {
			background-image: url('/images/reviews/bg_lamp_close_up_sml.jpg');
		}
		&.bg-waiting-room {
			background-image: url('/images/reviews/bg_waiting_room_sml.jpg');
		}
	}
	.services {
		background-color: $brand;
		padding: 15px;
		display: flex;
		justify-content: space-between;
		img {
			margin: 0;
		}
		.box {
			width: 100%;
			background-color: $white;
			text-align: center;
			padding: 10px;
		}
		.google img {
			margin-bottom: -5px;
		}
		.rate-a-biz img {
			margin-bottom: 9px;
		}
		.score {
			font-family: $alt-font;
			font-size: rem-calc(32);
			color: $brand;
			letter-spacing: -3px;
			line-height: 1;
			margin: 5px 0 0;
		}
		.stars {
			text-align: center;
			.inner {
				width: 60px;
				height: 11px;
				background: url('/images/reviews/stars_empty.png') no-repeat left top;
				display: inline-block;
				position: relative;
			}
			img {
				max-width: 60px;
				margin: 0;
				position: absolute;
				left: 0;
			}
			.filled-stars {
				height: 11px;
				overflow: hidden;
				position: relative;
			}
		}
	}
	.link {
		font-family: $alt-font;
		font-size: rem-calc(14);
		line-height: 1;
		margin: 0;
	}
}

@media only screen and (min-width:50em) {
	.review-ratings {
		.services .score {
			font-size: rem-calc(48);
		}
	}
}

/* @end */



/*------------------------------------*\
    @group Tiled Quotes
\*------------------------------------*/
.quote-tiles {
	blockquote {
		font-size: rem-calc(16);
		text-align: left;
		padding-left: 40px;
		padding-right: 0;
		line-height: 1.5;
		&::before {
			top: -39px;
		}
	}
	cite {
		margin-top: 10px;
	}
}


@media #{$medium-up} {
	.quote-tiles {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.tile {
			width: 48%;
		}
	}

}

/* @end */


/*------------------------------------*\
    @group Alert Bar
\*------------------------------------*/
.alert-bar {
	background-color: #ba5151;
	font-size: rem-calc(14);
	color: $white;
	padding: 15px 0 34px;
	position: relative;
	z-index: 300;
	p {
		line-height: 1.29;
		margin-bottom: 0;
	}
	.heading {
		font-weight: 700;
		margin-bottom: 10px;
	}
}

@media #{$medium-up} {
	.alert-bar {
		font-size: rem-calc(16);
		padding: 20px 0 40px;
		p {
			line-height: 1.25;
		}
	}
}

/* @end Alert Bar */