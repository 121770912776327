.page-footer {
    font-size: 14px;
    h3 {
        font-size: rem-calc(18);
        color: $brand;
        margin: 0 0 10px;
    }
    ul {
        padding: 0;
    }
    .copyright {
        font-size: rem-calc(14);
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
        a {
            margin: 12px 0 0;
            display: inline-block;
        }
    }
    a:not(.button) {
        color: $brand;
        &:hover,
        &:focus {
            color: $brand-accent;
        }
    }
    .social-media {
        a {
            font-size: 21px;
            color: $white;
            &:focus, &:hover {
                color: $white;
            }
        }
        .icon {
            padding: 5px 10px;
            transition: all .3s;
        }
        .icon.icon-youtube {
            padding: 5px 7px;
            &:hover, &:focus {
                background-color: lighten($youtube, 10%);
            }
        }
        .icon.icon-facebook {
            &:hover, &:focus {
                background-color: lighten($facebook, 10%);
            }
        }
    }
}

.page-footer .footer-contact {
    background: $off-white;
    .footer-col {
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .contact-location {
        display: table;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        p {
            margin: 0 0 12px;
        }
        a {
            font-weight: 700;
        }
        .button {
            margin-top: 4px;
        }
        .media {
            width: 115px;
            display: table-cell;
            vertical-align: top;
            padding-top: 5px;
        }
        .details {
            display: table-cell;
            vertical-align: top;
        }
        .heading {
            font-weight: 700;
            margin: 0 0 4px;
        }
        .hours {
            font-weight: bold;
            margin-bottom: 5px;
            + ul {
                margin-top: 0;
            }
        }
    }
    .appointment {
        background: $gold;
        background: linear-gradient(170deg, lighten($gold, 3%) 50%, $gold 50%);
        color: $white;
        padding-right: 40px;
        padding-left: 40px;
        margin-left: -15px;
        margin-right: -15px;
        h3 {
            color: $white;
            padding: 0 0 20px;
            margin: 0 0 20px;
            position: relative;
            &::before {
                content: '';
                background: $white;
                width: 60px;
                height: 5px;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }
}
.page-footer .copyright {
    margin-top: 12px;
    margin-bottom: 12px;
    p {
        margin: 0;
    }
}


@media #{$medium-up} {
    .page-footer {
        font-size: 1rem;
        .copyright {
            text-align: left;
            br {
                display: none;
            }
            a {
                margin: 0;
            }
        }
        .copyright-text {
            margin-top: 6px;
            .end-link {
                margin-left: 15px;
            }
        }
        .social-media {
            text-align: right;
        }
    }
    .page-footer .footer-contact {
        .footer-col {
            padding-top: 60px;
        }
        .contact-location {
            margin-bottom: 0;
        }
    }
}
@media #{$large-up} {
    .page-footer .footer-contact {
        .footer-col {
            min-height: 320px;
        }
        .appointment {
            margin-left: 0;
            margin-right: 0;
        }
    }
}