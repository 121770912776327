.page-header {
	background: $brand;
	a {
		color: $white;
		&:hover, &:focus {
			color: #DEE3AD;
		}
	}
	.logo {
		z-index: 150;
		img {
			padding: 10px 0;
			margin: 0;
			box-shadow: none;
		}
	}
	.search-wrap {
		padding-left: 0;
	}
	.search {
		background: none;
		height: auto;
		color: $white;
		border: none;
		border-bottom: solid 1px $white;
		border-radius: 0;
		box-shadow: none;
		padding: 0 5px;
		line-height: 1;
		&:hover, &:focus {
			background: none;
			border-color: $white;
		}
	}
	.search-form {
		.search-button {
			text-align: right;
		}
		button {
			width: auto;
			height: auto;
			color: $white;
			&:hover, &:focus {
				background: none;
				color: $dark-gray;
			}
			.icon {
				font-size: 16px;
				margin-right: 3px;
			}
		}
	}
}
.contact-header {
	background: $dark-gray;
	color: $white;
	position: relative;
	z-index: 200;
	.contact-toggle {
		font-family: $alt-font;
		font-size: 1rem;
		text-transform: uppercase;
		display: block;
		&.open .title {
			padding-bottom: 2px;
		}
	}
	.title {
		background: $dark-gray;
		text-align: center;
		padding: 8px 0;
		position: relative;
		z-index: 200;
	}
	.icon {
		width: 14px;
		font-size: 14px;
		vertical-align: 1px;
		margin: 0 0 0 5px;
		&.icon-pencil-square-o,
		&.icon-circle-thumb-up {
			margin: 0 5px 0 0;
		}
	}
	a:not(button):hover,
	a:not(button):focus {
		color: $brand-accent;
	}
}
.contact-header-wrap {
	background: $dark-gray;
	padding-top: 15px;
	position: absolute;
	transform: translateY(-100%);
	transition: all .4s;
	z-index: 150;
	&.open {
		transform: translatey(0%);
	}
	p {
		margin: 0 0 5px;
	}
	.contact-heading {
		font-weight: 700;
	}
	.contact-phone {
		display: inline-block;
		margin: 10px 0 10px;
		.icon {
			margin: 0 7px 0;
		}
	}
	.section {
		border-bottom: solid 1px rgba($brand-accent,.2);
		padding: 20px 0;
	}
	.button {
		margin: 0;
	}
	.search-section {
		padding: 12px 0;
	}
	.search-form {
		.search-button {
			padding-right: 0;
		}
		.search-input {
			padding-left: 0;
		}
		.search {
			margin: 0;
		}
	}
	.button-section {
		padding: 30px 0;
	}
	.link-section {
		padding: 13px 0 10px;
		text-align: center;
		.icon {
			font-size: 18px;
			vertical-align: -1px;
			margin-right: 5px;
		}
		.icon-thumbs-up {
			font-size: 16px;
		}
		a {
			display: inline-block;
			margin: 8px 0;
		}
	}
}
.top-nav {
	display: none;
}


@media only screen and (min-width:48.75em) {
	.contact-header {
		display: none;
	}
	.top-nav {
		background: rgba($white,.2);
		font-family: $alt-font;
		font-size: rem-calc(14);
		font-weight: 700;
		color: $white;
		display: block;
		padding: 16px 0 10px;
		ul {
			font-size: 1em;
			text-align: right;
			margin: 0;
		}
		li {
			display: inline-block;
			padding: 0;
			margin: 0 0 0 30px;
			&::before {
				display: none;
			}
		}
		input,
		button,
		input[type="text"] {
			margin: 0;
		}
		.icon-star,
		.icon-pencil-square-o {
			font-size: 17px;
		}
		.icon-pencil-square-o {
			margin-right: 3px;
			vertical-align: -1px;
		}
	}
}
@media only screen and (min-width:48.75em) and (max-width:58.74em) {
	.page-header .top-nav {
		height: 90px;
		> .row {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.page-header .logo-nav-row {
		position: relative;
	}
	.page-header .logo {
		position: absolute;
		top: -71px;
		margin-top: 0;
	}
	.page-header .nav {
		width: 100%;
	}
	.page-header nav ul {
		text-align: center;
	}
}

@media only screen and (min-width:58.75em) {
	.page-header {
		.top-nav {
			height: 52px;
		}
		.logo-nav-row {
			height: 58px;
		}
		.logo {
			width: 25%;
			margin-top: -52px;
			img {
				background: darken($brand, 5%);
				box-shadow: $box-shadow;
				padding: 25px 16px 20px;
				margin: 0 0 -66px;
			}
		}
		.nav {
			width: 75%;
		}
	}
}