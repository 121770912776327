.page-header .slicknav_nav {
	// Color is default state and alt is for alternate states (selected, hover, focus, etc.). Bg is for li and anchor hovers as needed
	$nav-color: $white;
	$nav-color-alt: $nav-color;
	$nav-bg: none;
	$nav-bg-alt: none;
	$nav-child-color: $white;
	$nav-child-color-alt: #DEE3AD;
	$nav-child-bg: none;
	$nav-child-bg-alt: none;

	width: 100%;
	background:  #376e6e;
	font-family: $heading-font;
	font-weight: 700;
	color: $white;
	padding: 0;
	margin: 0;
	list-style: none;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 100;
	border-bottom: solid 5px $brand-accent;
	ul {
		font-size: rem-calc(16);
		text-transform: uppercase;
		list-style: none;
		overflow: hidden;
		margin: 0;
		padding: 10px 0 10px;
		ul {
			font-size: rem-calc(15);
			text-transform: none;
			padding: 0;
		}
	}
	li {
		padding: 0;
		margin: 0;
	}
	a {
		color: $nav-color;
		text-decoration: none;
		padding: 7px 15px;
		display: block;
		&:hover,
		&:focus {
			color: #DEE3AD;
			text-decoration: none;
		}
	}
	li.selected > a {
		background: rgba($white, .2);
	}
	.slicknav_parent {
		.slicknav_row {
			padding: 7px 15px;
			position: relative;
			text-decoration: none;
			&::after {
				content: '\f008';
				font-family: $icon-font;
				font-size: 16px;
				color: $white;
				position: absolute;
				top: 8px;
				right: 15px;
				margin-left: 15px;
				transition: all .3s;
			}
			> a {
				padding-top: 4px;
				padding-bottom: 4px;
				padding-right: 0;
				padding-left: 0;
				display: inline-block;
			}
			&:hover,
			&:hover > a,
			&:hover::after,
			&:focus,
			&:focus > a,
			&:focus::after {
				color: #DEE3AD;
				text-decoration: none;
			}
		}
		&.slicknav_open {
			background: rgba($white, .2);
			& .slicknav_row {
				background: rgba($white, .2);
			}
		}
		&.slicknav_open .slicknav_row > a,
		&.slicknav_open .slicknav_row::after,
		&.selected .slicknav_row > a,
		&.selected .slicknav_row::after {
			color: $nav-color-alt;
		}
		.dropdown {
			background: $dark-gray;
			padding: 7px 10px;
			margin: 0;
			a {
				padding: 0 15px;
				&:hover,
				&:focus {
					color: $nav-child-color-alt;
				}
			}
			li {
				margin: 7px 0;
			}
			li.selected > a {
				background: none;
				color: $nav-child-color-alt;
			}
		}
	}
}
