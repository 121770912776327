.page-content {
    background: #FFFFFF;
    padding: 30px 0 12px;
}
.inner-hero .page-content {
	padding-top: 45px;
}

@media #{$medium-up} {
	.page-content {
	    padding: 70px 0 30px;
	}
	.inner-hero .page-content {
		padding-top: 70px;
	}
}
