.contact-page-options {
	h2 {
		font-size: rem-calc(24);
	}
	p {
		margin-bottom: 10px;
	}
	.column {
		padding-bottom: 20px;
	}
}