@font-face {
  font-family: 'semplicitapro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/SemplicitaPro-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/SemplicitaPro-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'serifa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/SerifaRoman.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/SerifaRoman.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'serifa';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/SerifaRoman.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/SerifaRoman.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}